
import { defineComponent, PropType, ref } from "vue";
import Input from "../../../shared/components/Input.vue";
import Checkbox from "../../../shared/components/Checkbox.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import CollectionHorizontalArtwork from "../shared/CollectionHorizontalArtwork.vue";
import CollectionActionsHeader from "../shared/CollectionActionsHeader.vue";
import { EsArtworkDto, useUserCollections } from "@kunsten/core";
import { useRouter } from "vue-router";
import Spinner from "../../../shared/components/Spinner.vue";

export default defineComponent({
    name: "CollectionCreate",
    components: { Input, Checkbox, CollectionHorizontalArtwork, CollectionActionsHeader, Spinner },
    props: {
        artwork: {
            type: Object as PropType<EsArtworkDto>,
            required: true,
        },
        owner: String,
    },
    data() {
        return {
            name: "",
            isPrivate: false,
        };
    },
    validations() {
        return {
            name: { required },
            isPrivate: {},
        };
    },
    setup() {
        const v$ = useVuelidate();
        const loading = ref(false);
        const router = useRouter();

        const { postUserCollection, postCollectArtwork } = useUserCollections();

        const submit = (name: string, isPrivate: boolean, owner: string, artwork: string) => {
            loading.value = true;
            postUserCollection({
                title: name,
                public: !isPrivate,
                owner: `/api/users/${owner}`,
                artworks: [],
            }).then(
                (res) => {
                    const userCollection = res.data.id;
                    if (userCollection) {
                        postCollectArtwork({
                            userCollection: `/api/user_collections/${userCollection}`,
                            artwork: `/api/artworks/${artwork}`,
                            sortNumber: 0,
                        }).then(
                            () => {
                                loading.value = false;
                                router.push({ name: "CollectionDetails", params: { id: userCollection } });
                            },
                            (err) => {
                                loading.value = false;
                                console.log(err);
                            }
                        );
                    } else {
                        loading.value = false;
                        router.push({ name: "Collections", query: { owner: owner } });
                    }
                },
                (err) => {
                    loading.value = false;
                    console.log(err);
                }
            );
        };

        return { v$, submit, loading };
    },
});
