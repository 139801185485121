import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withKeys as _withKeys, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-192934c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "checked"]
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tab_index = _resolveDirective("tab-index")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["checkbox", { 'checkbox--light': _ctx.light }])
  }, [
    _createElementVNode("input", {
      id: _ctx.checkboxId,
      type: "checkbox",
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onChange($event))),
      checked: _ctx.modelValue,
      "data-testid-input": ""
    }, null, 40, _hoisted_1),
    _withDirectives((_openBlock(), _createElementBlock("label", {
      for: _ctx.checkboxId,
      onKeydown: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.onChangeEnter()), ["enter"])),
      "data-testid-label": ""
    }, [
      _createTextVNode(_toDisplayString(_ctx.label), 1)
    ], 40, _hoisted_2)), [
      [_directive_tab_index, !_ctx.hidden]
    ])
  ], 2))
}