
import { defineComponent, computed, ref, watch } from "vue";
import { useAPI, useSingleResource, useUserCollections, UserCollectionDto } from "@kunsten/core";
import CollectionCreate from "./CollectionCreate.vue";
import CollectionUpdate from "./CollectionUpdate.vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "CollectionActions",
    components: { CollectionCreate, CollectionUpdate },
    setup() {
        const { userData } = useAPI();
        const route = useRoute();
        const router = useRouter();
        const view = ref("update");

        const { locale } = useI18n();
        const entityId = computed(() => route.query.id as string);
        const entityName = computed(() => "es_artworks");
        const entities = ref([] as UserCollectionDto[]);

        const userId = computed(() => {
            return userData.value?.id;
        });

        const changeView = (value: string) => {
            view.value = value;
            window.scrollTo(0, 0);
        };

        const { entity } = useSingleResource(entityName, entityId, { lang: locale.value });

        const { getUserCollections } = useUserCollections();

        watch(
            userData,
            () => {
                if (userData.value?.id) {
                    getUserCollections({ page: "1", pagination: "false", owner: userData.value?.id }).then((res) => {
                        entities.value = res.data["hydra:member"];
                    });
                }
            },
            { deep: true, immediate: true }
        );

        watch(
            entities,
            (value) => {
                if (!value.length) changeView("create");
            },
            { deep: true }
        );

        const goBack = () => {
            router.push({ name: "ArtworkPage", params: { id: entityId.value } });
        };

        return { userId, collections: entities, changeView, artwork: entity, view, goBack };
    },
});
