import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e644699a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "collection-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollectionUpdate = _resolveComponent("CollectionUpdate")!
  const _component_CollectionCreate = _resolveComponent("CollectionCreate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.view === 'update')
      ? (_openBlock(), _createBlock(_component_CollectionUpdate, {
          key: 0,
          entities: _ctx.collections,
          artwork: _ctx.artwork,
          owner: _ctx.userId,
          onOpen: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeView('create'))),
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goBack()))
        }, null, 8, ["entities", "artwork", "owner"]))
      : (_openBlock(), _createBlock(_component_CollectionCreate, {
          key: 1,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goBack())),
          artwork: _ctx.artwork,
          owner: _ctx.userId,
          onOpen: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeView('update')))
        }, null, 8, ["artwork", "owner"]))
  ]))
}