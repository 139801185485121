
import { defineComponent, PropType, ref } from "vue";
import CollectionHorizontalArtwork from "../shared/CollectionHorizontalArtwork.vue";
import CollectionHorizontalList from "../shared/CollectionHorizontalList.vue";
import CollectionActionsHeader from "../shared/CollectionActionsHeader.vue";
import { EsArtworkDto, UserCollectionDto } from "@kunsten/core";
import { useRouter } from "vue-router";
import { useUserCollections } from "@kunsten/core";

export default defineComponent({
    name: "CollectionUpdate",
    components: { CollectionHorizontalArtwork, CollectionActionsHeader, CollectionHorizontalList },
    props: {
        artwork: {
            type: Object as PropType<EsArtworkDto>,
            required: true,
        },
        entities: {
            type: Array as PropType<UserCollectionDto[]>,
            default: () => [],
        },
        owner: String,
    },
    setup(props, { emit }) {
        const router = useRouter();
        const loading = ref(false);

        const { postCollectArtwork } = useUserCollections();

        const open = () => {
            emit("open");
        };

        const add = (id: string) => {
            loading.value = true;
            postCollectArtwork({
                userCollection: `/api/user_collections/${id}`,
                artwork: `/api/artworks/${props.artwork.id}`,
                sortNumber: 100,
            }).then(
                () => {
                    loading.value = false;
                    router.push({ name: "CollectionDetails", params: { id: id } });
                },
                (err) => {
                    loading.value = false;
                    console.log(err);
                }
            );
        };

        return { open, add, loading };
    },
});
