
import { UserCollectionDto } from "@kunsten/core";
import { defineComponent, PropType } from "vue";
import CollectionHorizontalBlock from "./CollectionHorizontalBlock.vue";

export default defineComponent({
    name: "CollectionHorizontalList",
    components: { CollectionHorizontalBlock },
    props: {
        entities: {
            type: Array as PropType<UserCollectionDto[]>,
            default: () => [],
        },
        loading: Boolean,
    },
});
