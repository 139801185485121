
import { EsArtworkDto } from "@kunsten/core";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    name: "CollectionHorizontalArtwork",
    props: {
        artwork: {
            type: Object as PropType<EsArtworkDto>,
            required: true,
        },
    },
});
