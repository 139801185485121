
import { defineComponent } from "vue";

let uniqueId = 0;

export default defineComponent({
    name: "Checkbox",
    emits: ["change", "update:modelValue"],
    props: {
        id: String,
        label: String,
        modelValue: Boolean,
        light: Boolean,
        hidden: Boolean,
    },
    data() {
        return {
            currentId: uniqueId++,
        };
    },
    computed: {
        checkboxId() {
            return this.id ? `${this.id}-${this.currentId}` : `checkbox-${this.currentId}`;
        },
    },
    methods: {
        onChange(e: InputEvent): void {
            this.$emit("change", e);
            this.$emit("update:modelValue", (e.target as HTMLInputElement).checked);
        },
        onChangeEnter(): void {
            this.$emit("change");
            this.$emit("update:modelValue", !this.modelValue);
        },
    },
});
