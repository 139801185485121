import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eaa626a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "collection-horizontal-artwork"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "collection-horizontal-artwork-info" }
const _hoisted_4 = { class: "collection-horizontal-artwork-info-title" }
const _hoisted_5 = { class: "collection-horizontal-artwork-info-artists" }
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.artwork)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("figure", null, [
          (_ctx.artwork.reproductions.length)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.artwork.reproductions[0] + '/download?width=650&height=550',
                alt: ""
              }, null, 8, _hoisted_2))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.artwork.title || "-"), 1),
          _createElementVNode("h3", _hoisted_5, [
            _createTextVNode(_toDisplayString(_ctx.artwork.creatorsNames[0] || "-") + " ", 1),
            (_ctx.artwork.creatorsNames.length > 1)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, ", ..."))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}