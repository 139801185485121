import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c3eccf36"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "collection-horizontal-block p-5" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollectionBlockLabel = _resolveComponent("CollectionBlockLabel")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CollectionBlockLabel, {
      class: "mb-3",
      collection: _ctx.collection,
      centered: true,
      forcePublic: true
    }, null, 8, ["collection"]),
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('add', _ctx.collection.id))),
      class: "form px-5"
    }, [
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t("collection.add-to-this-collection")), 1))
        : (_openBlock(), _createBlock(_component_Spinner, {
            key: 1,
            size: "sm",
            style: {"margin":"auto"}
          }))
    ])
  ]))
}