import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5802a1f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "collection-horizontal-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollectionHorizontalBlock = _resolveComponent("CollectionHorizontalBlock")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entities, (collection) => {
      return (_openBlock(), _createBlock(_component_CollectionHorizontalBlock, {
        key: collection['@id'],
        collection: collection,
        loading: _ctx.loading,
        onAdd: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('add', $event))),
        class: "mb-4"
      }, null, 8, ["collection", "loading"]))
    }), 128))
  ]))
}