import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4fe5be15"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "collection-create" }
const _hoisted_2 = { class: "page-container pb-6" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "collection-create-cancel has-text-centered" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollectionActionsHeader = _resolveComponent("CollectionActionsHeader")!
  const _component_CollectionHorizontalArtwork = _resolveComponent("CollectionHorizontalArtwork")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CollectionActionsHeader, {
      onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
      class: "py-6"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("collection.create-new-collection")), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CollectionHorizontalArtwork, {
        class: "py-6",
        artwork: _ctx.artwork
      }, null, 8, ["artwork"]),
      _createElementVNode("form", {
        onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.submit(_ctx.name, _ctx.isPrivate, _ctx.owner, _ctx.artwork.id)), ["prevent"]))
      }, [
        _createVNode(_component_Input, {
          type: "text",
          modelValue: _ctx.v$.name.$model,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$.name.$model) = $event)),
          label: _ctx.$t('collection.name'),
          light: true,
          "aria-label": _ctx.$t('collection.name')
        }, null, 8, ["modelValue", "label", "aria-label"]),
        _createVNode(_component_Checkbox, {
          modelValue: _ctx.v$.isPrivate.$model,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$.isPrivate.$model) = $event)),
          label: _ctx.$t('collection.set-collection-as-private'),
          light: true
        }, null, 8, ["modelValue", "label"]),
        _createElementVNode("button", {
          disabled: _ctx.v$.$invalid,
          type: "submit",
          class: "btn gossamer py-4 px-5"
        }, [
          (!_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t("collection.create-collection")), 1))
            : (_openBlock(), _createBlock(_component_Spinner, {
                key: 1,
                size: "sm",
                style: {"margin":"auto"}
              }))
        ], 8, _hoisted_3)
      ], 32),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("a", {
          tabindex: "0",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('close'))),
          onKeydown: _cache[5] || (_cache[5] = _withKeys(($event: any) => (_ctx.$emit('close')), ["enter"]))
        }, _toDisplayString(_ctx.$t("general.cancel")), 33)
      ])
    ])
  ]))
}