
import { UserCollectionDto } from "@kunsten/core";
import { defineComponent, PropType } from "vue";
import CollectionBlockLabel from "./CollectionBlockLabel.vue";
import Spinner from "../../../shared/components/Spinner.vue";

export default defineComponent({
    name: "CollectionHorizontalBlock",
    components: { CollectionBlockLabel, Spinner },
    props: {
        collection: {
            type: Object as PropType<UserCollectionDto>,
            required: true,
        },
        loading: Boolean,
    },
});
